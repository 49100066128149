

.container-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-around{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.container-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.container-column{
  flex-direction: column;
  flex-wrap: wrap;
}

.form-group{
  margin: 3px 0px;
  width: 80%;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 30px;
}

.border-radius-sm {
  border-radius: 5;
}

.border-radius-md {
  border-radius: 10;
}

.border-radius-lg {
  border-radius: 20;
}

.shadow {
  -webkit-box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.36);
  box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.36);
}

.site-layout .site-layout-background {
  background: #fff;
}